
import Dashboard from "../../components/Dashboard"
import UsersBarrilitoTable from "../../components/UsersBarrilito"
import useFetchUsersBarrilito from "../../hooks/useFetchUsersBarrilito"
import UIButton from "../../components/UI/UIButton"
import useExcelExport from "../../components/UsersBarrilito/useExcelExport"

const UsersPage = () => {

  const { } = useFetchUsersBarrilito()
  const { exportToExcel } = useExcelExport()



  return (
    <Dashboard>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '2% 0'
      }}>
        <h1>USUARIOS</h1>

        <UIButton onClick={() => exportToExcel()} text='Descargar Usuarios'/>

      </div>
      <UsersBarrilitoTable />
    </Dashboard>
  )
}

export default UsersPage