import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useBlogContext } from '../../../context/BlogContext';
import { deleteBlog } from '../../../services/blog';
import ImgThumb from '../../ImgThumb';

const useBlogTable = () => {

    

    const navigate = useNavigate()
    const {setBlogs} = useBlogContext()

    const handleDelete = async (id: string) => {

        if (!window.confirm('¿Borrar Blog?')) return


        await deleteBlog(id)
        setBlogs(prev=>prev.filter(e=>e.id!==id))
        alert("Blog eliminado")

    }

    function formatDateToYear(date: Date): string {
        return date.toLocaleString("es-MX", { day:'2-digit', month: "2-digit",  year: "numeric"  });
      }

    const columns: GridColDef[] = [

        {
            field: 'fechaRegistro',
            headerName: 'Registro',
            width: 100,
            valueFormatter: (params) => formatDateToYear(params.value?.toDate())
        },
        {
            field: 'titulo',
            headerName: 'Titulo',
            width: 230
        },
        {
            field: 'descripcion_breve',
            headerName: 'Descripción Breve',
            width: 300
        },
        {
            field: 'contenido',
            headerName: 'Contenido',
            width: 200
        },
        {
            field: 'categorias',
            headerName: 'Categorias',
            width: 200
        },
        {
            field: 'favoritos',
            headerName: 'Favoritos',
            width: 80
        },
        {
            field: 'img_thumb',
            headerName: 'Imagen Thumb',
            width: 100,
            renderCell: (e) => <ImgThumb src={e.value} />
        },
        {
            field: 'img_contenido',
            headerName: 'Imagen Contenido',
            width: 120,
            renderCell: (e) => <ImgThumb src={e.value} />
        },
        {
            field: 'img_destacada',
            headerName: 'Imagen Destacada',
            width: 130,
            renderCell: (e) => <ImgThumb src={e.value} />
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 150,
            renderCell: (e) => {
                return <div style={{
                    display: 'flex',
                    gap: 4,
                }}>
                    <Button variant="contained" style={{ backgroundColor: 'transparent' }} onClick={() => {
                        navigate(`/blog/${e.id}`)
                    }}>  <img src={'/assets/img/editar.png'} alt="Button Image" style={{ width: '20px', height: '20px' }} />
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: 'transparent' }} onClick={() => {
                        handleDelete(e.id as string)
                    }}>
                        <img src={'/assets/img/eliminar.png'} alt="Button Image" style={{ width: '20px', height: '20px' }} />
                    </Button>
                </div>
            }
        },
    ];

    return {
        columns
    }

}

export default useBlogTable