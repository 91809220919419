import { useEffect, useState } from "react"
import { useVideoContext } from "../context/VideoContext"
import { getVideos } from "../services/video"

const useFetchVideos = () => {

    const {setVideos, videos} = useVideoContext()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        if(videos.length) return

        const fetchVideos = async () => {

            const videosDB = await getVideos()
            setVideos(videosDB)
            setIsLoading(false)

            console.log(videosDB)


        }

        fetchVideos()

    }, [])

    return {
        isLoading
    }

}

export default useFetchVideos