import { FC, useState } from "react"
import { SectionI, SectionsI } from "../../models/home"
import SectionsItem from "./Item"

interface Props {
    value: SectionsI
    onChange?: (e:SectionsI) => void,
    title?: string
}

const Sections: FC<Props> = ({ value, onChange, title }) => {

    const [sections, setSections] = useState(value)

    const handleChange = (e:SectionI, id:string) => {

        const data = {[id]:e}

        setSections(prev=> ({
            ...prev,
            ...data,
        }))

        onChange && onChange({
            ...sections,
            ...data,
        })
    }

    return (
        <div style={{
            background:'white', 
        }} className="contenedor">
            <h2 className="titSeccion">{title}</h2>
            {
                Object.keys(sections).map(k => {
                    return <SectionsItem 
                    key={k} id={k} value={sections[k]}
                    onChange={(e,id)=> {
                        handleChange(e,id)
                    }} />
                })
            }
        </div>
    )
}

export default Sections