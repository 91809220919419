import { DeltaStatic, Sources } from "quill"
import { FC } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
    ],
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
]

interface Props {
    value?: string,
    onChange?: (
        value: string,
        delta: DeltaStatic,
        source: Sources,
        editor: ReactQuill.UnprivilegedEditor
    ) => void,
}

const CustomTextArea: FC<Props> = ({ value, onChange }) => {
    return (
        <ReactQuill value={value}
            theme="snow"
            modules={modules}
            formats={formats}
            onChange={onChange} />
    )
}

export default CustomTextArea