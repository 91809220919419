import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import BlogTable from '../../components/Blog/Table';
import Dashboard from "../../components/Dashboard";
import useFetchBlogs from '../../hooks/useFetchBlogs';

const BlogPage = () => {

  const navigate = useNavigate()
  const {} = useFetchBlogs()

  return (
    <Dashboard>
     <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin:'2% 0'
      }}>
         <h1>BLOG</h1>
      
        <Button variant='contained' color='success' style={{
          marginBottom: '20px',
          background: '#000',
          fontFamily: 'WorkSans',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '16px',
          textAlign: 'center',
          textTransform: 'uppercase',
          color: '#FFF',
          padding: '17px 62px',
          border: 'none',
          cursor: 'pointer',
        }} onClick={() => {
          navigate("/blog/nuevo")
        }} >
          Nuevo blog
        </Button>
      </div>
      <BlogTable />
    </Dashboard>
  )
}

export default BlogPage