export const UUID = () => {
    return  btoa(Math.random().toString()).substring(0, 10)
}

export const isNewRegister = (id:string) => id==='nuevo'

export const generateSlug = (str:string) => {
    return str
      .toLowerCase()
      .replace(/[^\w ]+/g,'')
      .replace(/ +/g,'-');
  }