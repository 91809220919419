import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Avatar, Box, Button, Container, CssBaseline, TextField, Typography } from '@mui/material'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useState } from 'react'
import { auth } from '../firebase/firebase'
import Login from '../components/Auth/Login'

const LoginPage = () => {


    return (
        <>
            <Login/>         
        </>
    )
}

export default LoginPage