import React from 'react'
import useFetchUsersBarrilito from '../hooks/useFetchUsersBarrilito'
import LinesChart from '../components/Graficas/lines'
import BarsCharts from '../components/Graficas/bars'
import Dashboard from '../components/Dashboard'
import Graficas from '../components/Graficas'

const GraficasPage = () => {
  const {} = useFetchUsersBarrilito()

  return (
    <Dashboard>
      <Graficas/>
    </Dashboard>
   
  )
}

export default GraficasPage