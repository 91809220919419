import { useEffect, useState } from "react"
import { useBlogContext } from "../context/BlogContext"
import { getBlogs } from "../services/blog"

const useFetchBlogs = () => {

    const {setBlogs, blogs} = useBlogContext()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        if(blogs.length) return

        const fetchBlogs = async () => {


            const blogsDB = await getBlogs()
            setBlogs(blogsDB)
            setIsLoading(false)


        }

        fetchBlogs()

    }, [])

    return {
        isLoading
    }

}

export default useFetchBlogs