import TextField from "@mui/material/TextField"
import { FC, useEffect, useState } from "react"
import { TiktokItemI } from "../../models/home"
import { handleChangeFile } from "../../utils/files"
import CustomInputFile from "../Custom/InputFile"

interface Props {
  value:TiktokItemI,
  onChange?: (e:TiktokItemI, id:string) => void,
  id:string
}

const TiktokItem: FC<Props> = ({value, onChange, id}) => {

  const [item, setItem] = useState(value)

  useEffect(() => {
    onChange && onChange(item, id)
  }, [item])

  return (
    <div style={{
      display:'flex',
      justifyContent:'space-between',
      gap:10
    }}>
      <TextField 
        value={item.url} label="Url" name="url" onChange={e => {
          setItem(prev=>({...prev,url:e.target.value}))
        }} className="txtField" />
        <CustomInputFile   type="video" label="Video (301 x 488px)" img={item.imagen} onChange={e => {
          setItem(prev => ({...prev,media:handleChangeFile(e)}))
        }}   />
    </div>
  )
}

export default TiktokItem