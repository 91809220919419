import React, { ReactNode, createContext, useContext, useState } from "react";
import { UserBarrilitoI, UsersBarrilitoI } from "../models/usersBarrilito";


interface UserBarrilitoContextType {
    usersBarrilito: UsersBarrilitoI;
    setUsersBarrilito: React.Dispatch<React.SetStateAction<UsersBarrilitoI>>
    userBarrilito:UserBarrilitoI
    setUserBarrilito: React.Dispatch<React.SetStateAction<UserBarrilitoI>>
}

const UserBarrilitoContext = createContext<UserBarrilitoContextType>({
    usersBarrilito: [],
    setUsersBarrilito: () => { },
    userBarrilito:{} as any,
    setUserBarrilito: () => { },
});

export const useUserBarrilitoContext = () => useContext(UserBarrilitoContext);

export const UserBarrilitoProvider = ({ children }: { children?: ReactNode }) => {

    const [usersBarrilito, setUsersBarrilito] = useState<UserBarrilitoI[]>([])
    const [userBarrilito, setUserBarrilito] = useState<UserBarrilitoI>({} as any)

    return (
        <UserBarrilitoContext.Provider value={{ usersBarrilito, setUsersBarrilito, userBarrilito, setUserBarrilito }}>
            {children}
        </UserBarrilitoContext.Provider>
    );
};
