import { DeltaStatic, Sources } from "quill"
import { FC } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const modules = {
    toolbar: [
        ['link']
    ],
}

interface Props {
    value?: string,
    onChange?: (
        value: string,
        delta: DeltaStatic,
        source: Sources,
        editor: ReactQuill.UnprivilegedEditor
    ) => void,
}

const CustomTextAreaNosotros: FC<Props> = ({ value, onChange }) => {
    return (
        <ReactQuill value={value}
            theme="snow"
            modules={modules}
            onChange={onChange} />
    )
}

export default CustomTextAreaNosotros