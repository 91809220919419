import { useEffect, useState } from "react"
import { useUserContext } from "../context/UserContext"
import { getUsers } from "../services/users"


const useFetchUsers = () => {

    const {users, setUsers} = useUserContext()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        if(users.length) return

        const fetchUsers = async () => {


            const usersDB = await getUsers()
            setUsers(usersDB)
            setIsLoading(false)

            console.log("fetch usuarios", usersDB)



        }

        fetchUsers()

    }, [])

    return {
        isLoading
    }

}

export default useFetchUsers