import Dashboard from "../components/Dashboard"
import Home from "../components/Home"
import Loading from "../components/Loading"
import useFetchHome from "../hooks/useFetchHome"

const HomePage = () => {

    const { home } = useFetchHome()

    return (
        <Dashboard>
            {!home ? <Loading /> : <Home home={home} />}
        </Dashboard>
    )
}

export default HomePage