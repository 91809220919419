import ImageList from '@mui/material/ImageList';
import { FC, useState } from 'react';
import { GalleriesI } from '../../models/productos';
import GalleryItem from './Item';
import { Button } from '@mui/material';
import { deleteField } from 'firebase/firestore';
import { updateProducto } from '../../services/productos/productos';
import { removeFile } from '../../services/storage';

interface Props {
  gallery: GalleriesI,
  title?: string,
  productId: string,
  onDelete?: (id: string) => void,
}

const Gallery: FC<Props> = ({ gallery, title, productId, onDelete }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const sortedGallery = Object.keys(gallery)
    .map(k => ({ ...gallery[k], id: k }))
    .sort((a, b) => {
      if (a.orden === b.orden) {
        if (a.fechaRegistro && b.fechaRegistro) {
          return b.fechaRegistro.seconds - a.fechaRegistro.seconds;
        }
      }
      return a.orden - b.orden;
    });

  const handleToggleSelect = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter(item => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      const deletePromises = selectedItems.map(async (id) => {
        const item = gallery[id];
        const data = {
          [`galeria.${id}`]: deleteField(),
        };

        await Promise.allSettled([
          updateProducto(productId, data, undefined, false),
          removeFile(item.path_imagen),
        ]);

        onDelete && onDelete(id);
      });

      if(!window.confirm('¿Borrar imagen?')) return
      await Promise.all(deletePromises);

      setSelectedItems([]);

      alert("Imágenes eliminadas correctamente");
    } catch (e) {
      console.log("Error", e);
    }
  };

  return (
    <div className='contenedor'>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <h1>{title}</h1>
        <Button style={{height: '40px'}} variant='contained' color='error' onClick={handleDeleteSelected}>Borrar seleccionados</Button>
      </div>
      <ImageList cols={5} rowHeight={230}>
        {sortedGallery.map((item, index) => (
          <GalleryItem
            item={item}
            id={item.id}
            productId={productId}
            key={index}
            onDelete={onDelete}
            onToggleSelect={handleToggleSelect}
          />
        ))}
      </ImageList>
    </div>
  );
};

export default Gallery;
