import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Dashboard from '../../components/Dashboard'
import DescargableAdd from '../../components/Descargables/Add/Add'
import { DescargableI } from '../../models/descargables'
import { getDescargable } from '../../services/descargable'
import { isNewRegister } from '../../utils/strings'

const DescargablesIdPage = () => {

    const {id} = useParams()
    const [descargable, setDescargable] = useState<DescargableI>()

    useEffect(() => {
        if(!id) return
        if(isNewRegister(id)) return

        const fetchDescargable = async () => {
            const descargableDB = await getDescargable(id) as any
            setDescargable(descargableDB)
        }

        fetchDescargable()
    },[id])


  return (
    <Dashboard>
      <DescargableAdd descargable={descargable} key={descargable?.descripcion_breve}/>
    </Dashboard>
  )
}

export default DescargablesIdPage