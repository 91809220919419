import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
    const navigate = useNavigate()

    const handleLogout = async () => {
        try {
            const auth = getAuth();
            await signOut(auth);
            navigate('/')
            
        } catch (e) {
            console.error(e)
        }
    }

    return handleLogout

}

export default useLogout