import { useEffect, useState } from "react"
import { HomeI } from "../models/home"
import { getHome } from "../services/home"

const useFetchHome = () => {

    const [home, setHome] = useState<HomeI>()

    useEffect(() => {

        const fetchHome = async () => {

            const homeDB = await getHome()
            setHome(homeDB)

        }

        fetchHome()

    }, [])

    return {
        home,
    }

}

export default useFetchHome