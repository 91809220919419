import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ProductoI } from "../models/productos"
import { getProducto } from "../services/productos/productos"
import { isNewRegister } from "../utils/strings"

const useFetchProduct = () => {

    const { id } = useParams()
    const [producto, setProducto] = useState<ProductoI>()

    useEffect(() => {

        if (!id) return
        if (isNewRegister(id)) return

        const fetchProducto = async () => {

            const productoDB = await getProducto(id) as any
            setProducto(productoDB)
        }

        fetchProducto()

    }, [id])

    return {
        producto,
        setProducto,
    }

}

export default useFetchProduct