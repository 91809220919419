import React, { ReactNode, createContext, useContext, useState } from "react";
import { VideosI } from "../models/video";


interface VideoContextType {
    videos: VideosI;
    setVideos: React.Dispatch<React.SetStateAction<VideosI>>;
}

const VideoContext = createContext<VideoContextType>({
    videos: [],
    setVideos: () => { },
});

export const useVideoContext = () => useContext(VideoContext);

export const VideoProvider = ({ children }: { children?: ReactNode }) => {

    const [videos, setVideos] = useState<VideosI>([])

    return (
        <VideoContext.Provider value={{ videos, setVideos }}>
            {children}
        </VideoContext.Provider>
    );
};
