import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { storage } from "../firebase/firebase"

export const uploadFile = async (file: File, path:string) => {
    
    const rute = `${path}/${file.name}`
    const storageRef = ref(storage,rute)

    await uploadBytes(storageRef, file as Blob)
    const url = await getDownloadURL(ref(storage, rute))

    return {
        path:rute,
        url
    }

}


export const removeFile = async (path:string) => {
    const storageRef = ref(storage, path);
    await deleteObject(storageRef);
}