import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Dashboard from '../../components/Dashboard'
import VideoAdd from '../../components/Video/Add/Add'
import { VideoI } from '../../models/video'
import { getVideo } from '../../services/video'
import { isNewRegister } from '../../utils/strings'

const VideoIdPage = () => {

  const {id} = useParams()
  const [video, setVideo] = useState<VideoI>()

  useEffect(() => {

    if(!id) return 
    if(isNewRegister(id)) return

    const fetchVideo = async () => {

      const videoDB = await getVideo(id) as any
      setVideo(videoDB)
    }

    fetchVideo() 

  }, [id])

  return (
    <Dashboard>
        <VideoAdd video={video} key={video?.descripcion_breve}/>
    </Dashboard>
  )
}

export default VideoIdPage