import { Timestamp } from 'firebase/firestore'
import { listAll, ref } from 'firebase/storage'
import { useState } from 'react'
import { GalleryModalAddProps } from '.'
import { storage } from '../../../firebase/firebase'
import { updateProducto } from '../../../services/productos/productos'
import { uploadFile } from '../../../services/storage'
import { UUID } from '../../../utils/strings'

const useGalleryModalAdd = ({ productId, onSucces }: GalleryModalAddProps) => {

    const [isOpen, setIsOpen] = useState(false)
    const handleOpen = () => setIsOpen(true)
    const handleClose = () => setIsOpen(false)
    const [image, setImage] = useState<File>()

    const [orden, setOrden] = useState<number>()


    const handleAdd = async () => {

        if (!image) return alert("No hay imagen seleccionada")

        const { name } = image
        const storagePath = `Productos/${productId}`

        const storageRef = ref(storage, storagePath)
        const fileList = await listAll(storageRef)
        const fileNames = fileList.items.map((item) => item.name)

        if (fileNames.includes(name)) {
            return alert("Ya existe una imagen con el mismo nombre")
        }

        const { path, url } = await uploadFile(image, storagePath)

        try {

            const data = {
                galeria: {
                    [UUID()]: {
                        imagen: url,
                        path_imagen: path,
                        orden: orden || 0,
                        fechaRegistro: Timestamp.now(),
                    } 
                }
            }

            await updateProducto(productId, data)
            onSucces && onSucces(data)
            handleClose()


        } catch (e) {

            console.error("Error al guardar ", e)

        }

    }

    return {
        isOpen,
        handleClose,
        handleOpen,
        setImage,
        orden,
        setOrden,
        image,
        handleAdd,
    }

}

export default useGalleryModalAdd
