import { useEffect, useState } from 'react'
import Dashboard from '../../components/Dashboard'
import ProductosTable from '../../components/Productos/Table'
import useFetchHome from '../../hooks/useFetchHome'
import { getProductos } from '../../services/productos/productos'

const ProductosPage = () => {

    const [productos, setProductos] = useState<any[]>([])
    const {home} = useFetchHome()

    useEffect(() => {

      const fetchProductos = async () => {
        const productosDB = await getProductos()
        setProductos(productosDB)
      }

      
      fetchProductos()
    },[])

    

  return (
    <Dashboard>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin:'2% 0'
      }}>
      <h1>PRODUCTOS</h1></div>
        <ProductosTable productos={productos} home={home}   />
    </Dashboard>
  )
}

export default ProductosPage