import { DocumentData, QuerySnapshot } from "firebase/firestore"

export const docsToArray = (snap: QuerySnapshot<DocumentData>) => {

    const list: any[] = []

    snap.docs.forEach(e => {

        list.push({
            ...e.data(),
            id: e.id,
        })

    })

    return list
}