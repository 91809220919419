import { uploadFile } from "./storage"

interface HandleUploadImageProps {
    data: any,
    property: string,
    id: string,
    route: string
    PATH?:string
}

export const handleUploadImage = async ({ 
    data, property, id, route,
    PATH = 'Secciones/Home'
}: HandleUploadImageProps) => {

    const media = data[property][id].media
    if (!media) return

    const {url, path} = await uploadFile(
        media,
        `${PATH}/${route}`
    )

    data[property][id].imagen = url
    data[property][id].path_imagen = path

    delete data[property][id].media

    return
}