import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../../../context/UserContext";
import useForm from "../../../hooks/useForm";
import { UserI } from "../../../models/users";
import { createUser } from "../../../services/auth";
import { updateUser } from "../../../services/users";
import { isNewRegister } from "../../../utils/strings";
import { USER_BASE } from "./constants";

const useBlogAdd = (user?: UserI) => {
    const navigate = useNavigate();
    const { setUsers } = useUserContext();
    const { onChange, formData, setFormData } = useForm(user || USER_BASE);
    const { id } = useParams();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            if (isNewRegister(`${id}`)) {
                
                const userRes = await createUser(formData)

                setUsers((prev) => [...prev, userRes]);

                alert("Admin añadido");
                navigate("/admin");
            } else {
                const userRes = await updateUser(`${id}`, { ...formData });

                setUsers((prev) =>
                    prev.map((e) => {
                        if (e.id !== id) return e;
                        return { ...userRes, id };
                    })
                );
                alert("Admin actualizado");
                navigate("/admin");
            }
        } catch (e) {
            console.error(e);
            alert('Email en uso')
        }
    };

    return {
        handleSubmit,
        onChange,
        formData,
        setFormData,
    };
};

export default useBlogAdd;
