import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useBlogContext } from "../../../context/BlogContext"
import useForm from "../../../hooks/useForm"
import { BlogI } from "../../../models/blog"
import { BlogImagesI, addBlog, updateBlog } from "../../../services/blog"
import { generateSlug, isNewRegister } from "../../../utils/strings"
import { Timestamp } from "firebase/firestore"


const initialStateValues = {
  img_portada: '',
  titulo: '',
  descripcion_breve: '',
  contenido: '',
  img_thumb: '',
  slug: '',
  img_contenido: '',
  img_destacada: '',
  destacado: false,
  categorias: '',
  restringido: false
}

const useBlogAdd = (blog?: BlogI) => {

  const [images, setImages] = useState<BlogImagesI>({
    imgThumb: undefined,
    imgContenido: undefined,
    imgPortada: undefined
  })

  const navigate = useNavigate()
  const { setBlogs } = useBlogContext()
  const { onChange, formData, setFormData } = useForm(blog || initialStateValues)
  const { id } = useParams()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault()


    try {

      formData.slug = generateSlug(formData.titulo)

      if (isNewRegister(`${id}`)) {

        const blogRes = await addBlog(
          {
            ...formData,
            activo: true,
            fechaRegistro: Timestamp.now(),
          },
          images
        )

        setBlogs(prev => [...prev, blogRes])

        alert("Blog añadido")
        navigate('/blog')
      } else {
        const blogRes = await updateBlog(
          `${id}`,
          { ...formData },
          images
        )

        setBlogs(prev => prev.map(e => {
          if (e.id !== id) return e
          return { ...blogRes, id }
        }))
        alert("Blog actualizado")
        navigate('/blog')
      }

    } catch (e) {
      console.error(e)
    }

  }

  return {
    handleSubmit,
    onChange,
    formData,
    images,
    setImages,
    setFormData,
  }

}

export default useBlogAdd