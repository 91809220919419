import React, { ReactNode, createContext, useContext, useState } from "react";
import { BlogI, BlogsI } from "../models/blog";


interface BlogContextType {
    blogs: BlogsI;
    setBlogs: React.Dispatch<React.SetStateAction<BlogsI>>;
}

const BlogContext = createContext<BlogContextType>({
    blogs: [],
    setBlogs: () => { },
});

export const useBlogContext = () => useContext(BlogContext);

export const BlogProvider = ({ children }: { children?: ReactNode }) => {

    const [blogs, setBlogs] = useState<BlogI[]>([])

    return (
        <BlogContext.Provider value={{ blogs, setBlogs }}>
            {children}
        </BlogContext.Provider>
    );
};
