import { useState } from "react"

const UseForm = <T>(initialState:T) => {

    const [formData, setFormData] = useState(initialState)
    
    const onChange = (e:any) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]:e.target.value,
        }))
    }

    return {
        formData,
        onChange,
        setFormData
    }

}

export default UseForm