import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { useUserBarrilitoContext } from '../../context/UsersBarrilitoContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function LinesChart() {
  const { usersBarrilito } = useUserBarrilitoContext();

  const edadRanges = ["15 - 25", "25 - 35", "35 - 45", "50 +"];
  const edadCounts = [0, 0, 0, 0];
  

  // Obtener el año actual
  const currentYear = new Date().getFullYear();

  // Calcular la edad para cada usuario y contar las ocurrencias en cada rango
  usersBarrilito.forEach(user => {
    const yearOfBirth = user.anio;
    const age = currentYear - yearOfBirth;

    if (age >= 15 && age <= 25) {
      edadCounts[0]++;
    } else if (age >= 25 && age <= 35) {
      edadCounts[1]++;
    } else if (age >= 35 && age <= 45) {
      edadCounts[2]++;
    } else {
      edadCounts[3]++;
    }
  });

  const data = {
    labels: edadRanges,
    datasets: [
      {
        label: 'Edad',
        data: edadCounts,
        borderColor: 'rgb(209, 143, 119)',
      },
      
    ]
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: 1000,
        ticks: {
          stepSize: 100,
          beginAtZero: true,
          color: 'rgb(10, 10, 10)'
        }
      },
      x: {
        ticks: { color: 'rgb(10, 10, 10)' }
      }
    }
  };

  return (
    <div style={{width: '700px', height: '550px', marginTop: '100px'}}>
      <Line data={data} options={options} />
    </div>
  )
}
