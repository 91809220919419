import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore"
import { FS_FIELDS } from "../constants/db"
import { db } from "../firebase/firebase"
import { docsToArray } from "../utils/arrays"
import { uploadFile } from "./storage"

export interface VideoImagesI {
    imgThumb?: File
    videoContenido?: File
    imgDestacada?: File
}

const handleUploadImages = async (
    data: any, {videoContenido, imgThumb, imgDestacada}: VideoImagesI
) => {

    if (imgThumb) {
        const {path, url} = await uploadFile(imgThumb,"Video")
        data.img_thumb = url
        data.path_img_thumb = path
    }

    if(videoContenido){
        const {path, url} = await uploadFile(videoContenido,"Video")
        data.video_contenido = url
        data.path_video_contenido = path
    }

    if(imgDestacada){
        const {path, url} = await uploadFile(imgDestacada,"Video")
        data.img_destacada = url
        data.path_img_destacada = path
    }

    return data

}

export const addVideo = async (data: any, images: VideoImagesI) => {

    data = await handleUploadImages(data, images)

    const snap = await addDoc(collection(db, FS_FIELDS.VIDEO), data)

    return {
        ...data,
        id:snap.id,
    }

}

export const updateVideo = async (id: string, data: any, images: VideoImagesI) => {

    data = await handleUploadImages(data, images)

    await updateDoc(doc(db, FS_FIELDS.VIDEO, id), data)

    return data

}

export const deleteVideo = async (id: string) => {

    return await updateDoc(doc(db, FS_FIELDS.VIDEO, id), {
        activo: false
    })

}

export const getVideo = async (id: string) => {

    const snap = await getDoc(doc(db, FS_FIELDS.VIDEO, id))

    return snap.data()

}

export const getVideos = async () => {

    const videoRef = collection(
        db, FS_FIELDS.VIDEO,
    )

    const snap = await getDocs(
        query(
            videoRef,
            where("activo", "==", true),
            orderBy("fechaRegistro", "desc")

        )
    )

    return docsToArray(snap)

}