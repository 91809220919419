import React from 'react'
import LinesChart from './lines'
import BarsCharts from './bars'

const Graficas = () => {
  return (
    <>

        <div style={{display: 'flex', marginTop: '50px'}}>
            <LinesChart/>
            <BarsCharts/>
        </div>

    </>
  )
}

export default Graficas