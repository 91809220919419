import { doc, getDoc, updateDoc } from "firebase/firestore"
import { FS_FIELDS } from "../constants/db"
import { db } from "../firebase/firebase"
import { ContactanosI, NosotrosI } from "../models/nosotros"
import { handleUploadImage } from "./media"
import { uploadFile } from "./storage"

export interface NosotrosFilesI {
    imgThumb?: File
}


export const getNosotros = async () => {

    const snap = await getDoc(
        doc(db, FS_FIELDS.SECCIONES, FS_FIELDS.NOSOTROS)
    )

    return snap.data() as NosotrosI

}

interface UpdateNosotrosProps {
    data:NosotrosI
}

const handleUploadDistribuidoresImagenes = async ({data}: UpdateNosotrosProps) => {

    console.log(data)

    await Promise.allSettled(
        Object.keys(data.distribuidores.imagenes).map((id) =>
            handleUploadImage({
                data:data.distribuidores,
                property: "imagenes",
                id,
                route: "Nosotros",
                PATH:'Secciones/Nosotros'
            })
        )
    )

    return data

}

const handleUploadMedias = async ({data}: UpdateNosotrosProps) => {

    const promises: Promise<any>[] = []

    if(data.media){
        const {url, path} = await uploadFile(data.media, 'Secciones/Nosotros')
        data.imagen = url
        data.pathImagen = path
        delete data.media
    }

    promises.push(handleUploadDistribuidoresImagenes({data}))
    // promises.push(handleUploadSections(data))
    // promises.push(handleUploadTiktok(data))

    await Promise.all(promises)

    return data

}

export const updateNosotros = async ({data}:UpdateNosotrosProps) => {

    data = await handleUploadMedias({data})

    return await updateDoc(
        doc(db, FS_FIELDS.SECCIONES, FS_FIELDS.NOSOTROS), 
        data as any
    )

}

export const getContactanos = async () => {
    const snap = await getDoc(doc(db, FS_FIELDS.SECCIONES, FS_FIELDS.CONTACTANOS))

    return snap.data() as ContactanosI
}