import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDescargablesContext } from "../../../context/DescargablesContext"
import useForm from "../../../hooks/useForm"
import { DescargableI } from "../../../models/descargables"
import { DescargableFilesI, addDescargable, updateDescargable } from "../../../services/descargable"
import { isNewRegister } from "../../../utils/strings"
import { Timestamp } from "firebase/firestore"

const initialStateValues = {
  titulo: '',
  descripcion_breve: '',
  img_thumb: '',
  descargar_pdf: '',
  categorias: '',
  restringido: false
}

const useDescargableAdd = (descargable?: DescargableI) => {

  const [images, setImages] = useState<DescargableFilesI>({
    imgThumb: undefined,
    uploadPDF: undefined,
  })
  const {setDescargables} = useDescargablesContext()
  const navigate = useNavigate()
  const { onChange, formData, setFormData } = useForm(descargable || initialStateValues)
  const { id } = useParams()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault()


    try {

      if (isNewRegister(`${id}`)) {
        
        const descargableRes = await addDescargable(
          {
            ...formData,
            activo: true,
            fechaRegistro: Timestamp.now(),
          },
          images
        )

        alert("Descargable añadido")
        setDescargables(prev=>[...prev,descargableRes])
        navigate('/descargable')

      } else {
        const descargableRes = await updateDescargable(
          `${id}`,
          { ...formData },
          images
        )
        alert("Descargable actualizado")
        setDescargables(prev=>prev.map(e => {

          if(e.id!==id) return e

          return {...descargableRes, id}

        }))
        navigate('/descargable')
      }

    } catch (e) {
      console.error(e)
    }

  }

  return {
    handleSubmit,
    onChange,
    formData,
    setFormData,
    images,
    setImages,
  }

}

export default useDescargableAdd