import { useBlogContext } from '../../../context/BlogContext';
import CustomTable from '../../Custom/Table';
import useBlogTable from './useBlogTable';

const BlogTable = () => {

    const { columns } = useBlogTable()
    const {blogs} = useBlogContext()

    const blogsDate = [...blogs].sort((a, b) => b.fechaRegistro.seconds - a.fechaRegistro.seconds);
    

    return <CustomTable rows={blogsDate} columns={columns} />
    
}

export default BlogTable