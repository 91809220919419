import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import BlogAdd from "../../components/Blog/Add/Add"
import { BlogI } from "../../models/blog"
import { getBlog } from "../../services/blog"
import { isNewRegister } from "../../utils/strings"
import Dashboard from "../../components/Dashboard"


const BlogIdPage = () => {

  const {id} = useParams()
  const [blog, setBlog] = useState<BlogI>()

  useEffect(() => {

    if(!id) return 
    if(isNewRegister(id)) return

    const fetchBlog = async () => {

      const blogDB = await getBlog(id) as any
      setBlog(blogDB)

    }

    fetchBlog()

  }, [id])

  return (
    <Dashboard>
      <BlogAdd blog={blog} key={blog?.descripcion_breve} />
    </Dashboard>
  )
}

export default BlogIdPage