import { Box, TextField } from '@mui/material'
import { FC, useEffect } from 'react'
import useForm from '../../../hooks/useForm'
import { ContactanosI } from '../../../models/nosotros'

interface Props{
  value: ContactanosI,
  onChange?: (e:ContactanosI) => void,
}

const ContactanosSection:FC<Props> = ({ value, onChange }) => {

  const {formData, onChange:onChangeForm} = useForm(value)

  useEffect(() => {
    onChange && onChange(formData)
  }, [formData])

  return (
    <Box
        style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            background: '#fff',
            paddingBottom: '10%'
        }}>
        <h1>Contáctanos</h1>

        <TextField 
        label="Email" variant='outlined' name="email" 
        style={{ background: '#fff' }} required value={formData.email} onChange={onChangeForm} />
    </Box>
  )
}

export default ContactanosSection