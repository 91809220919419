import { TextField } from "@mui/material"
import { FC, useEffect } from "react"
import UseForm from "../../hooks/useForm"
import { MarcaI } from "../../models/marcas"

interface Props {
    id: string,
    onChange?: (e: MarcaI, id: string) => void,
    value: MarcaI,
}

const MarcasItem: FC<Props> = ({ id, onChange, value }) => {

    const { formData, setFormData } = UseForm<MarcaI>(value)

    useEffect(() => {
        onChange && onChange(formData, id)
    }, [formData])

    return (
        <TextField label="Titulo" value={formData.titulo} name="titulo"
            onChange={e => {
                setFormData(prev => ({ ...prev, titulo: e.target.value }))
            }} />
    )
}

export default MarcasItem