import { FC } from "react"
import ExternalLink from "./ExternalLink"

interface Props {
    src?: string
}

const VideoThumb: FC<Props> = ({src}) => {

    if(!src) return null

    return <ExternalLink src={src} text="Ver video" />

    return (
        <video src={src} width={100} height={100} />
    )
}

export default VideoThumb