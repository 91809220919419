import { FC } from "react"
import { HomeI } from "../../models/home"
import Sections from "../Sections"
import SelectImages from "../SelectImages"
import SocialLinks from "../SocialLinks"
import Tiktok from "../Tiktok"
import useHome from "./useHome"
import SelectMedias from "../SelectMedias"

interface Props {
  home: HomeI
}

const Home: FC<Props> = ({ home }) => {

  const {
    onChangeVideoPrincipal,
    onSubmitForm, setFormData
  } = useHome(home)

  return (

    <form onSubmit={onSubmitForm} style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    }}>
      <h1>HOME</h1>


      <div style={{
        background: 'white'
      }} className="contenedor">

        <h2 className="titSeccion">Media</h2>

        <SelectMedias value={home.medias}
          title="Imágenes" onChange={e => {
            setFormData(prev => ({ ...prev, medias: e }))
          }} />
      </div>



      <SocialLinks value={home.redesSociales}
        onChange={e => {
          setFormData(prev => ({ ...prev, redesSociales: e }))
        }} />


      <Sections value={home.secciones}
        onChange={e => {
          setFormData(prev => ({ ...prev, secciones: e }))
        }} title="Descubre todo lo que tenemos para ti" />


      <SelectImages value={home.productosDestacados}
        title="Productos destacados" onChange={e => {
          setFormData(prev => ({ ...prev, productosDestacados: e }))
        }} />


      <Tiktok value={home.tiktok} onChange={e => {
        setFormData(prev => ({ ...prev, tiktok: e }))
      }} />


      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
        <input type="submit" value="Cancelar" className="btnCancelar" />
        <input type="submit" value="Aceptar" className="btnAceptar" />
      </div>
    </form>

  )
}

export default Home