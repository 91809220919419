import React, { ReactNode, createContext, useContext, useState } from "react";
import { DescargablesI } from "../models/descargables";

interface DescargablesContextType {
    descargables: DescargablesI;
    setDescargables: React.Dispatch<React.SetStateAction<DescargablesI>>;
}

const DescargablesContext = createContext<DescargablesContextType>({
    descargables: [],
    setDescargables: () => { },
});

export const useDescargablesContext = () => useContext(DescargablesContext);

export const DescargablesProvider = ({ children }: { children?: ReactNode }) => {

    const [descargables, setDescargables] = useState<DescargablesI>([])

    return (
        <DescargablesContext.Provider value={{ descargables, setDescargables }}>
            {children}
        </DescargablesContext.Provider>
    );
};
