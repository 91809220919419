import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useVideoContext } from "../../../context/VideoContext"
import useForm from "../../../hooks/useForm"
import { VideoI } from "../../../models/video"
import { VideoImagesI, addVideo, updateVideo } from "../../../services/video"
import { generateSlug, isNewRegister } from "../../../utils/strings"
import { Timestamp } from "firebase/firestore"

const initialStateValues = {
  titulo: '',
  descripcion_breve: '',
  contenido: '',
  img_thumb: '',
  img_destacada: '',
  slug: '',
  url_vimeo: '',
  destacado: false,
  categorias: '',
  restringido: false
}

const useVideoAdd = (video?: VideoI) => {

  const { setVideos } = useVideoContext()
  const [images, setImages] = useState<VideoImagesI>({
    imgThumb: undefined,
  })

  const navigate = useNavigate()
  const { onChange, formData, setFormData } = useForm(video || initialStateValues)
  const { id } = useParams()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault()


    try {

      formData.slug = generateSlug(formData.titulo)

      if (isNewRegister(`${id}`)) {
        const videoRes = await addVideo(
          {
            ...formData,
            activo: true,
            fechaRegistro: Timestamp.now(),
          },
          images
        )
        setVideos(prev => [...prev, videoRes])
        alert("Video añadido")
        navigate('/video')
      } else {
        const videoRes = await updateVideo(
          `${id}`,
          { ...formData },
          images
        )
        alert("Video actualizado")
        setVideos(prev => prev.map(e => {
          if (e.id !== id) return e
          return { ...videoRes, id }
        }))
        navigate('/video')
      }

    } catch (e) {
      console.error(e)
    }

  }

  return {
    handleSubmit,
    onChange,
    formData,
    images,
    setImages,
    setFormData
  }

}

export default useVideoAdd