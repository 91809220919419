import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useUserContext } from './context/UserContext';
import { auth } from './firebase/firebase';
import BlogPage from './pages/blog';
import BlogIdPage from './pages/blog/[id]';
import DescargablesPage from './pages/descargable';
import DescargablesIdPage from './pages/descargable/[id]';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import NosotrosPage from './pages/nosotros';
import ProductosPage from './pages/productos';
import ProductosIdPage from './pages/productos/[id]';
import GalleryPage from './pages/productos/gallery';
import VideoPage from './pages/video';
import VideoIdPage from './pages/video/[id]';
import { getUser } from './services/users';
import AdminPage from './pages/admin';
import AdminIdPage from './pages/admin/[id]';
import UsersPage from './pages/users';
import GraficasPage from './pages/graficas';

function App() {

  const [currentUser, setCurrentUser] = useState(null)
  const {setUser, user} = useUserContext()

  useEffect(() => {
    onAuthStateChanged(auth, handleUserStateChanged)
  }, [])

  const handleUserStateChanged = async (user: any) => {

    setCurrentUser(user)

    if(!user) return

    const userDB = await getUser(user.uid)

    if(!userDB) {
      const auth = getAuth();
      await signOut(auth)
      setCurrentUser(null)
      return
    }
    
    setUser(userDB)

  }

  return (
    <Router>
      <Routes>
        <Route path="*" element={currentUser ? <Navigate to='/home' /> : <LoginPage />} />
        {currentUser && <Route path='/home' element={<HomePage />} />}
        {currentUser && <Route path='/blog' element={<BlogPage />} />}
        {currentUser && <Route path='/blog/:id' element={<BlogIdPage />} />}
        {currentUser && <Route path='/home' element={<HomePage />} />}
        {currentUser && <Route path='/video' element={<VideoPage />} />}
        {currentUser && <Route path='/video/:id' element={<VideoIdPage />} />}
        {currentUser && <Route path='/descargable' element={<DescargablesPage />} />}
        {currentUser && <Route path='/descargable/:id' element={<DescargablesIdPage />} />}
        {currentUser && <Route path='/productos' element={<ProductosPage />} />}
        {currentUser && <Route path='/producto/:id' element={<ProductosIdPage />} />}
        {currentUser && <Route path='/galeria/:id' element={<GalleryPage />} />}
        {currentUser && <Route path='/nosotros' element={<NosotrosPage />} />}
        {(currentUser && user.rol==='Administrador' ) && <Route path='/admin' element={<AdminPage />} />}
        {(currentUser && user.rol==='Administrador' ) && <Route path='/admin/:id' element={<AdminIdPage />} />}
        {(currentUser && user.rol==='Administrador') && <Route path='/usuarios' element={<UsersPage/>} />}
        {<Route path='/graficas' element={<GraficasPage/>} />}
      </Routes>
    </Router>
  )
  
}

export default App;
