import { Button } from '@mui/material'
import { useNavigate } from 'react-router'
import Dashboard from '../../components/Dashboard'
import VideoTable from '../../components/Video/Table'
import useFetchVideos from '../../hooks/useFetchVideos'

const VideoPage = () => {

  const navigate = useNavigate()
  const { } = useFetchVideos()

  return (
    <Dashboard>
      
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin:'2% 0'
      }}>
        <h1>VIDEO</h1>
        <Button variant='contained' color='success' style={{
          marginBottom: '20px', background: '#000',
          fontFamily: 'WorkSans',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '16px',
          textAlign: 'center',
          textTransform: 'uppercase',
          color: '#FFF',
          padding: '17px 62px',
          border: 'none',
          cursor: 'pointer',
        }} onClick={() => {
          navigate("/video/nuevo")
        }} >
          Nuevo video
        </Button>
      </div>
      <VideoTable />
    </Dashboard>
  )
}

export default VideoPage