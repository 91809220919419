import { Box, Button, CircularProgress } from '@mui/material'
import { FC, useState } from 'react'
import { HomeI } from '../../models/home'
import { updateHomeData } from '../../services/home'
import { uploadFile } from '../../services/storage'
import { handleChangeFile } from '../../utils/files'
import CustomInputFile from '../Custom/InputFile'

interface Props {
  home: HomeI
}

const Catalogo: FC<Props> = ({ home }) => {

  const [file, setFile] = useState<File>()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {

    if (!file) return alert("Tienes que seleccionar un archivo")

    try {

      setIsLoading(true)

      const { path, url } = await uploadFile(file, "Secciones/Home")

      const data = {
        productos: {
          url_catalogo: url,
          path_url_catalogo: path,
        }
      }

      await updateHomeData(data)

      alert("Pdf subido correctamente")

    } catch (e) {

      console.error("Error ", e)

    } finally {
      setIsLoading(false)
    }

  }

  return (
    <>
      <Box style={{
        display: 'flex'
      }}>
        <CustomInputFile label='Catalogo PDF' type='pdf' 
        img={home.productos.url_catalogo}
          onChange={e => {
            setFile(handleChangeFile(e))
          }} />
        <Button onClick={handleClick}>
          {!isLoading ? 'Guardar' : <CircularProgress />}
        </Button>
      </Box>
    </>
  )
}

export default Catalogo