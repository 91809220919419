import { FC } from "react"
import ExternalLink from "./ExternalLink"

interface Props {
    src?: string
}

const ImgVideo: FC<Props> = ({ src }) => {

    if (!src) return null

    return (
        <>
        
        

        <ExternalLink src={src} text="Vista previa" />

        
        </>
    )
}

export default ImgVideo