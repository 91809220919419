import { FormEvent, useState } from "react"
import UseForm from "../../hooks/useForm"
import { HomeI, HomeMediasI } from "../../models/home"
import { updateHome } from "../../services/home"
import { handleChangeFile } from "../../utils/files"

const useHome = (home:HomeI) => {

    const {formData, onChange, setFormData} = UseForm(home)
    const [medias, setMedias] = useState<HomeMediasI>({})

    const onChangeVideoPrincipal = (e:React.ChangeEvent<HTMLInputElement>) => {
        setMedias(prev => ({
            ...prev,
            videoPrincipal:handleChangeFile(e)
        }))
    }

    const onSubmitForm = async (e:FormEvent) => {

        e.preventDefault()

        try {

            await updateHome({
                data:formData,
                medias,
            })

            alert("Datos actualiados")

        } catch(e){
            alert("Ocurrió un error")
            console.error("Error ", e)
        }

    }

    return {
        onChangeVideoPrincipal,
        formData,
        onChange,
        onSubmitForm,
        setFormData,
    }

}

export default useHome