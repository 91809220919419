import Dashboard from '../../components/Dashboard'
import ProductoAdd from '../../components/Productos/Add/Add'
import useFetchProduct from '../../hooks/useFetchProduct'

const ProductosIdPage = () => {

  const {producto} = useFetchProduct()

  return (
    <Dashboard>
      <ProductoAdd producto={producto} key={producto?.descripcion_breve}/>
    </Dashboard>
  )
}

export default ProductosIdPage