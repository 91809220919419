import { DataGrid } from '@mui/x-data-grid'
import { FC } from 'react'
import { HomeI } from '../../../models/home'
import { ProductosI } from '../../../models/productos'
import Catalogo from '../../Catalogo'
import useProductosTable from './useProductosTable'


interface Props {
    productos: ProductosI
    home?: HomeI
}

const ProductosTable: FC<Props> = ({ productos, home }) => {

    const { columns } = useProductosTable()




    return (
        <>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={productos}
                    columns={columns}
                    pagination
                    // pageSizeOptions={[5]}
                    style={{ background: '#fff' }}
                />
            </div>

            { home && <Catalogo home={home} /> }

        </>

    )
}

export default ProductosTable