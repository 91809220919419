import { Box } from '@mui/material'
import { FC, useEffect } from 'react'
import UseForm from '../../hooks/useForm'
import { DistribuidoresI } from '../../models/nosotros'
import CustomTextAreaNosotros from '../Custom/TextAreaNosotros'
import SelectImages from '../SelectImages'

interface Props {
  value: DistribuidoresI
  onChange?: (e: DistribuidoresI) => void,
}

const Distribuidores: FC<Props> = ({ value, onChange }) => {

  const { formData, onChange: onChangeform, setFormData } = UseForm(value)

  useEffect(() => {
    onChange && onChange(formData)
  }, [formData])

  return (
    <Box style={{ backgroundColor: '#fff' }}>
      <h1>Distribuidores</h1>

      <SelectImages value={formData.imagenes}
        title="Imágenes" onChange={e => {
          setFormData(prev => ({ ...prev, imagenes: e }))
        }} />
        
      <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '7%' }}>

        <div style={{ width: '30%', padding: '5px', }} >
          <h3 className="espacio">Columna 1</h3>


          <CustomTextAreaNosotros 
            value={formData.texto1}
            onChange={(e) => {
              setFormData(prev => ({
                ...prev,
                texto1: e
              }))
            }}
          />
        </div>

        <div style={{ width: '30%', padding: '5px' }}>
          <h3 className="espacio">Columna 2</h3>
          <CustomTextAreaNosotros
            value={formData.texto2}
            onChange={(e) => {
              setFormData(prev => ({
                ...prev,
                texto2: e
              }))
            }}
          />
        </div>

        <div style={{ width: '30%', padding: '5px' }}>
          <h3 className="espacio">Columna 3</h3>
          <CustomTextAreaNosotros
            value={formData.texto3}
            onChange={(e) => {
              setFormData(prev => ({
                ...prev,
                texto3: e
              }))
            }}
          />
        </div>

      </div>

      <p style={{ marginLeft: '40px'}}>40 caracteres por renglon</p> 

    </Box>
  )
}

export default Distribuidores