import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

const useUsersBarrilitoTable = () => {


    const columns: GridColDef[] = [

        {
            field: 'nombre',
            headerName: 'Nombre',
            width: 200
        },
        {
            field: 'apellidoPaterno',
            headerName: 'Apellido Paterno',
            width: 200
        },
        {
            field: 'apellidoMaterno',
            headerName: 'Apellido Materno',
            width: 200
        },
        {
            field: 'interes',
            headerName: 'Interes',
            width: 200
        },
        {
            field: 'interes_otro',
            headerName: 'Otro interes',
            width: 200
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200
        },
        {
            field: 'ultimoAcceso',
            headerName: 'Ultimo acceso',
            width: 200
        },
        {
            field: 'sexo',
            headerName: 'sexo',
            width: 200
        },
        {
            field: 'anio',
            headerName: 'Edad',
            width: 200,
            valueGetter: (params: any) => {
                const yearOfBirth = params.row.anio as number;
                const currentYear = new Date().getFullYear();
                const age = currentYear - yearOfBirth;
                return age;
            }
        }
        
    ];


    return {
        columns
    }
}

export default useUsersBarrilitoTable