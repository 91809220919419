import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useVideoContext } from '../../../context/VideoContext';
import { deleteVideo } from '../../../services/video';
import ImgThumb from '../../ImgThumb';
import VideoThumb from '../../VideoThumb';

const useVideoTable = () => {

    const navigate = useNavigate()
    const {setVideos} = useVideoContext()

    const handleDelete = async (id: string) => {

        if (!window.confirm('¿Borrar Video?')) return

        await deleteVideo(id)
        setVideos(prev=>prev.filter(e=>e.id!==id))
        alert("Video eliminado")

    }

    
    function formatDateToYear(date: Date): string {
        return date.toLocaleString("es-MX", { day:'2-digit', month: "2-digit",  year: "numeric"  });
    }

    const columns: GridColDef[] = [

        {
            field: 'fechaRegistro',
            headerName: 'Registro',
            width: 100,
            valueFormatter: (params) => formatDateToYear(params.value?.toDate())
        },
        {
            field: 'titulo',
            headerName: 'Titulo',
            width: 200
        },
        {
            field: 'descripcion_breve',
            headerName: 'Descripción Breve',
            width: 200
        },
        {
            field: 'contenido',
            headerName: 'Contenido',
            width: 200
        }, {
            field: 'categorias',
            headerName: 'Categorias',
            width: 200
        },
        {
            field: 'favoritos',
            headerName: 'Favoritos',
            width: 200
        },
        {
            field: 'img_thumb',
            headerName: 'Imagen Thumb',
            width: 200,
            renderCell: (e) => <ImgThumb src={e.value} />
        },
        {
            field: 'img_destacada',
            headerName: 'Imagen Destacada',
            width: 200,
            renderCell: (e) => <ImgThumb src={e.value} />
        },
        {
            field: 'url_vimeo',
            headerName: 'Vimeo',
            width: 200,
            renderCell: (e) => <VideoThumb src={e.value} />
        },

        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 200,
            renderCell: (e) => {
                return <div style={{
                    display: 'flex',
                    gap: 4,
                }}>
                    <Button variant="contained" style={{ backgroundColor: 'transparent' }} onClick={() => {
                        navigate(`/video/${e.id}`)
                    }}>
                        <img src={'/assets/img/editar.png'} alt="Button Image" style={{ width: '20px', height: '20px' }} />
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: 'transparent' }}
                        onClick={() => {
                            handleDelete(e.id as string)
                        }}>
                        <img src={'/assets/img/eliminar.png'} alt="Button Image" style={{ width: '20px', height: '20px' }} />
                    </Button>
                </div>
            }
        },
    ];

    return {
        columns
    }

}

export default useVideoTable