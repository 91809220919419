import { FC, useEffect } from "react"
import UseForm from "../../hooks/useForm"
import { TiktokI } from "../../models/home"
import TiktokItem from "./Item"

interface Props {
    value: TiktokI,
    onChange?: (e: TiktokI) => void,
}

const Tiktok: FC<Props> = ({ value, onChange }) => {

    const { formData, setFormData } = UseForm(value)

    const items = Object.keys(formData).map(k => formData[k])
    .sort((a, b) => a.orden - b.orden);


    useEffect(() => {
        onChange && onChange(formData)
    }, [formData])

    return (
        <div  className="contenedor">
            <h2 className="titSeccion">Tiktok</h2>
            {
                items.map((item, index) => {
                    const id = Object.keys(formData)[index]
                    return <TiktokItem key={id} value={item} id={id}
                        onChange={(e, id) => {
                            setFormData(prev => ({
                                ...prev,
                                [id]:e,
                            }))
                        }} />
                })
            }
        </div>
    )
}

export default Tiktok