import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { DescargableI } from '../../../models/descargables'
import CustomInputFile from '../../Custom/InputFile'
import useDescargableAdd from './useDescargableAdd'


interface Props {
  descargable?: DescargableI,
}

const DescargableAdd: FC<Props> = ({ descargable }) => {

  const { 
    onChange, 
    handleSubmit, 
    formData, 
    setFormData,
    setImages 
  } = useDescargableAdd(descargable)
  const navigate = useNavigate()

  return (
    <>

      <Box component='form' onSubmit={handleSubmit} style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        background: '#fff',
        padding: '4%',
        paddingBottom: '10%'

      }}>

        <h1>Descargables</h1>
       

        <TextField label="Titulo" variant='outlined' value={formData.titulo} name="titulo" onChange={onChange} style={{ background: '#fff' }} required />

        <TextField label="Descripcion Breve" variant='outlined' value={formData.descripcion_breve} name="descripcion_breve" onChange={onChange} style={{ background: '#fff' }} required />

        <FormControl>
          <InputLabel >Categorias</InputLabel>
          <Select label="Orden" name="categorias" onChange={onChange} value={formData.categorias}>
            <MenuItem value={'Ejercicios de palabras'} >Ejercicios de palabras</MenuItem>
            <MenuItem value={'Ejercicios para recortar'}>Ejercicios para recortar</MenuItem>
            <MenuItem value={'Ejercicios de memoria'}>Ejercicios de memoria</MenuItem>
            <MenuItem value={'Ejercicios para contar'}>Ejercicios para contar</MenuItem>
            <MenuItem value={'Ejercicios de trazo'}>Ejercicios de trazo</MenuItem>
            <MenuItem value={'Ejercicios de números'}>Ejercicios de números</MenuItem>
            <MenuItem value={'Ejercicios de colores'}>Ejercicios de colores</MenuItem>
            <MenuItem value={'Ejercicios didácticos'}>Ejercicios didácticos</MenuItem>
            <MenuItem value={'Ejercicios para colorear'}>Ejercicios para colorear</MenuItem>
            <MenuItem value={'Ejercicios de ingles'}>Ejercicios de ingles</MenuItem>
            <MenuItem value={'Ejercicios de verbos'}>Ejercicios de verbos</MenuItem>
            <MenuItem value={'Ejercicios de acciones'}>Ejercicios de acciones</MenuItem>
            <MenuItem value={'Ejercicios de matemáticas'}>Ejercicios de matemáticas</MenuItem>
            <MenuItem value={'Ejercicios de escritura'}>Ejercicios de escrituras</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>Restringido</InputLabel>

          <Checkbox checked={formData.restringido} onChange={() => {
              setFormData(prev=>({
                ...prev,
                restringido: !prev.restringido
              }))
            }} />
        </FormControl>
      
        
        <CustomInputFile label='Imagen thumb (848 x 566px)' onChange={e => {
          setImages(prev => ({
            ...prev,
            imgThumb: e.target.files ? e.target.files[0] : undefined
          }))
        }} img={formData.img_thumb} />

        <CustomInputFile type='pdf' label='PDF' onChange={e => {
          setImages(prev => ({
            ...prev,
            uploadPDF: e.target.files ? e.target.files[0] : undefined
          }))
        }} img={formData.descargar_pdf} />

        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end',}}>
          <Button color="success" variant="contained" type='submit' style={{ background: '#000',
            fontFamily: 'WorkSans',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#FFF',
            padding: '17px 62px',
            border: 'none',
            cursor: 'pointer', }} >Guardar</Button>
          <Button color="error" variant="contained" onClick={() => {
            navigate("/descargable")
          }} style={{background: '#D8D8D8',
          fontFamily: 'WorkSans',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '16px',
          textAlign: 'center',
          textTransform: 'uppercase',
          color: '#5E5E5E',
          padding: '17px 62px',
          border: 'none',
          marginLeft: '20px',
          cursor: 'pointer',}}>Cancelar</Button>
        </div>

      </Box>
    </>



  )
}

export default DescargableAdd