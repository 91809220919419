import { Box, Button, TextField } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProductoI } from '../../../models/productos'
import CustomInputFile from '../../Custom/InputFile'
import useProductoAdd from './useProductoAdd'

//titulo, descripcion, distribuidores, columnas del card, email contacto
// corporativo: direccion, telefono, email 

interface Props {
    producto?: ProductoI,
}

const ProductoAdd: FC<Props> = ({ producto }) => {

    const { onChange, handleSubmit, formData, setImages } = useProductoAdd(producto)
    const navigate = useNavigate()

    return (
        <>

            <Box component='form' onSubmit={handleSubmit} style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                background: '#fff',
                padding: '4%',
                paddingBottom: '10%',

            }}>

                <h1>Productos</h1>


                <TextField label="Titulo" variant='outlined' value={formData.titulo} name="titulo" onChange={onChange} style={{ background: '#fff' }} required />

                <TextField label="Descripcion Breve" variant='outlined' value={formData.descripcion_breve} name="descripcion_breve" onChange={onChange} style={{ background: '#fff' }} required />


                <CustomInputFile label='Imagen Background (3678 x 1110)' onChange={e => {
                    setImages(prev => ({
                        ...prev,
                        imgBackground: e.target.files ? e.target.files[0] : undefined
                    }))
                }} img={formData.img_background} />



                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
                    <Button color="success" variant="contained" type='submit' style={{
                        background: '#000',
                        fontFamily: 'WorkSans',
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '16px',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        color: '#FFF',
                        padding: '17px 62px',
                        border: 'none',
                        cursor: 'pointer',
                    }}  >Guardar</Button>
                    
                    <Button color="error" variant="contained" onClick={() => {
                        navigate("/productos")
                    }} style={{
                        background: '#D8D8D8',
                        fontFamily: 'WorkSans',
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '16px',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        color: '#5E5E5E',
                        padding: '17px 62px',
                        border: 'none',
                        marginLeft: '20px',
                        cursor: 'pointer',
                    }}>Cancelar</Button>
                </div>

            </Box>
        </>



    )
}

export default ProductoAdd