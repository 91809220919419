import { Button } from "@mui/material"
import Dashboard from "../../components/Dashboard"
import useFetchUsers from "../../hooks/useFetchUsers"
import { useNavigate } from "react-router-dom"
import AdminTable from "../../components/CreateUser/Table"

const AdminPage = () => {

  const navigate = useNavigate()
  const {} = useFetchUsers()


  return (
    <Dashboard>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '2% 0'
      }}>
        <h1>ADMIN</h1>

        <Button variant='contained' color='success' style={{
          marginBottom: '20px',
          background: '#000',
          fontFamily: 'WorkSans',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '16px',
          textAlign: 'center',
          textTransform: 'uppercase',
          color: '#FFF',
          padding: '17px 62px',
          border: 'none',
          cursor: 'pointer',
        }} onClick={() => {
          navigate("/admin/nuevo")
        }} >
          Nuevo usuario
        </Button>
      </div>
      <AdminTable/>
    </Dashboard>
  )
}

export default AdminPage