import { deleteApp, initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { firebaseConfig } from "../firebase/firebase";
import { UserI } from "../models/users";
import { addUser } from "./users";

export const createUser = async (user: UserI) => {

    const app = initializeApp(firebaseConfig, 'loginAlterno');
    const auth = getAuth(app)

    const userCredential = await createUserWithEmailAndPassword(
        auth,
        user.email,
        user.password
    );

    const res = await addUser({
        ...user,
        activo: true,
        fechaRegistro: new Date(),
        id: userCredential.user.uid,
    })

    await deleteApp(app)

    return res

}