import { useUserBarrilitoContext } from '../../context/UsersBarrilitoContext';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const useExcelExport = () => {

    const { usersBarrilito } = useUserBarrilitoContext();

    const exportToExcel = () => {
        const data = usersBarrilito.map(usersBarrilito => ({
            nombre: usersBarrilito.nombre,
            'apellido paterno': usersBarrilito.apellidoPaterno,
            'apellido materno': usersBarrilito.apellidoMaterno,
            interes: usersBarrilito.interes,
            'otro interes': usersBarrilito.interes_otro,
            email: usersBarrilito.email,
            sexo: usersBarrilito.sexo,
            dia: usersBarrilito.dia,
            mes: usersBarrilito.mes,
            'año': usersBarrilito.anio,
            'Ultimo acceso': usersBarrilito.ultimoAcceso
            
        }));
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Usuarios');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(dataBlob, 'usuarios.xlsx');
    };


  return {
    exportToExcel,
    usersBarrilito
  }
}

export default useExcelExport