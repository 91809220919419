import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import useForm from "../../../hooks/useForm"
import { ProductoI } from "../../../models/productos"
import { ProductoImagesI, addProducto, updateProducto } from "../../../services/productos/productos"
import { isNewRegister } from "../../../utils/strings"


const initialStateValues = {
    titulo: '',
    descripcion_breve: '',
    img_background: '',
}

const useProductoAdd = (producto?: ProductoI) => {

    const [images, setImages] = useState<ProductoImagesI>({
        imgBackground: undefined,
    })

    const navigate = useNavigate()
    const { onChange, formData } = useForm(producto || initialStateValues)
    const { id } = useParams()

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault()


        try {

            if (isNewRegister(`${id}`)) {
                await addProducto(
                    {
                        ...formData,
                        activo: true,
                        fechaRegistro: new Date(),
                        galeria:{},
                    },
                    images
                )

                alert("Producto añadido")
                navigate('/productos')
            } else {
                await updateProducto(
                    `${id}`,
                    { ...formData },
                    images
                )
                alert("Producto actualizado")
                navigate('/productos')
            }

        } catch (e) {
            console.error(e)
        }

    }

    return {
        handleSubmit,
        onChange,
        formData,
        images,
        setImages,
    }

}

export default useProductoAdd