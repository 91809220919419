export const enum FS_FIELDS {
    BLOG = "Blog",
    SECCIONES = "Secciones",
    HOME = "Home",
    VIDEO = "Video",
    DESCARGABLE = "Descargable",
    PRODUCTOS = "Productos",
    NOSOTROS = "Nosotros",
    CONTACTANOS = "Contactanos",
    CATALOGO = "Catalogo",
    USUARIOS = "Usuarios",
}