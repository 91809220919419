import { Button } from '@mui/material'
import { useVideoContext } from '../../../context/VideoContext'
import CustomTable from '../../Custom/Table'
import useVideoTable from './useVideoTable'


const VideoTable = () => {

    const { columns } = useVideoTable()
    const {videos} = useVideoContext()

    const videosDate = [...videos].sort((a, b) => b.fechaRegistro.seconds - a.fechaRegistro.seconds);

    return <CustomTable rows={videosDate} columns={columns} />
        
}

export default VideoTable