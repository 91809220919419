import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore"
import { FS_FIELDS } from "../../constants/db"
import { db } from "../../firebase/firebase"
import { docsToArray } from "../../utils/arrays"
import { uploadFile } from "../storage"

export interface ProductoImagesI {
    imgBackground?: File
}

const handleUploadImages = async (
    data: any, {imgBackground}: ProductoImagesI
) => {

    if (imgBackground) {
        const {path, url} = await uploadFile(imgBackground,"Productos")
        data.img_background = url
        data.path_img_background = path
    }

    return data

}

export const addProducto = async (data: any, images: ProductoImagesI) => {

    data = await handleUploadImages(data, images)

    return await addDoc(collection(db, FS_FIELDS.PRODUCTOS), data)

}

export const updateProducto = async (id: string, data: any, images?: ProductoImagesI, merge = true) => {

    if(images){
        data = await handleUploadImages(data, images)
    }

    if(merge){
        return await setDoc(doc(db, FS_FIELDS.PRODUCTOS, id), data,{merge:true})
    }

    console.log("Actualiza producto", data)

    return await updateDoc(doc(db, FS_FIELDS.PRODUCTOS, id), data)

}

export const deleteProducto = async (id: string) => {

    return await updateDoc(doc(db, FS_FIELDS.PRODUCTOS, id), {
        activo: false
    })

}

export const getProducto = async (id: string) => {

    const snap = await getDoc(doc(db, FS_FIELDS.PRODUCTOS, id))

    return snap.data()

}

export const getProductos = async () => {

    const productoRef = collection(
        db, FS_FIELDS.PRODUCTOS,
    )

    const snap = await getDocs(
        query(
            productoRef,
            where("activo", "==", true),
            orderBy("fechaRegistro", "desc")

        )
    )

    return docsToArray(snap)

}