import { HomeI, HomeMediasI } from "../../models/home";
import { handleUploadImage } from "../media";
import { uploadFile } from "../storage";

const PATH = 'Secciones/Home'

const handleUploadMainVideo = async (data: HomeI, medias: HomeMediasI) => {

    if (medias.videoPrincipal) {
        const {url, path} = await uploadFile(medias.videoPrincipal, PATH)
        data.videoPrincipal = url
        data.pathVideoPrincipal = path
    }

    return data

}

const handleUploadFeaturedProducts = async (data: HomeI) => {

    await Promise.allSettled(
        Object.keys(data.productosDestacados).map((id) =>
            handleUploadImage({
                data,
                property: "productosDestacados",
                id,
                route: "ProductosDestacados"
            })
        )
    )

    return data

}

const handleUploadMedia = async (data: HomeI) => {

    await Promise.allSettled(
        Object.keys(data.medias).map((id) =>
            handleUploadImage({
                data,
                property: "medias",
                id,
                route: "Medias"
            })
        )
    )

    return data

}

const handleUploadSections = async (data: HomeI) => {

    await Promise.allSettled(
        Object.keys(data.secciones).map((id) =>
            handleUploadImage({
                data,
                property: "secciones",
                id,
                route: "Secciones"
            })
        )
    );

    return data

}

const handleUploadTiktok = async (data: HomeI) => {

    await Promise.allSettled(
        Object.keys(data.tiktok).map((id) =>
            handleUploadImage({
                data,
                property: "tiktok",
                id,
                route: "Tiktok"
            })
        )
    );

    return data

}

export const handleUploadMedias = async (data: HomeI, medias: HomeMediasI) => {

    const promises: Promise<any>[] = []

    promises.push(handleUploadMainVideo(data, medias))
    promises.push(handleUploadFeaturedProducts(data))
    promises.push(handleUploadSections(data))
    promises.push(handleUploadTiktok(data))
    promises.push(handleUploadMedia(data))

    await Promise.all(promises)

    return data

}