import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { deleteVideo } from '../../../services/video';
import ImgThumb from '../../ImgThumb';

const useProductosTable = () => {

    const navigate = useNavigate()

    const columns: GridColDef[] = [
       
        {
            field: 'titulo',
            headerName: 'Titulo',
            width: 200
        },
        {
            field: 'descripcion_breve',
            headerName: 'Descripción Breve',
            width: 200
        },
        {
            field: 'img_background',
            headerName: 'Imagen Background',
            width: 200,
            renderCell: (e) => <ImgThumb src={e.value} />
        },
        
        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 200,
            renderCell: (e) => {
                return <div style={{
                    display: 'flex',
                    gap: 4,
                }}>
                    {/*<Button variant="contained" onClick={() => {
                        navigate(`/blog/${e.id}`)
                    }}>Ver</Button>*/}
                    <Button variant="contained" style={{ backgroundColor: 'transparent'}} onClick={() => {
                        navigate(`/producto/${e.id}`)
                    }}>
                          <img src={'/assets/img/editar.png'} alt="Button Image" style={{ width: '20px', height: '20px' }} />
                 
                    </Button>
                    <Button variant="contained"  style={{ backgroundColor: 'transparent'}}
                    onClick={() => { navigate(`/galeria/${e.id}`)
                    }}>   
                    <img src={'/assets/img/galeria.png'} alt="Button Image" style={{ width: '20px', height: '20px' }} />
                    </Button>
                </div>
            }
        },
    ];

    return {
        columns
    }

}

export default useProductosTable