import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore"
import { FS_FIELDS } from "../constants/db"
import { db } from "../firebase/firebase"
import { docsToArray } from "../utils/arrays"
import { uploadFile } from "./storage"

export interface DescargableFilesI {
    imgThumb?: File
    uploadPDF?: File
}

const handleUploadImages = async (
    data: any, {imgThumb, uploadPDF}: DescargableFilesI
) => {

    if (imgThumb) {
        const {path, url} = await uploadFile(imgThumb,"Descargables")
        data.path_img_thumb = path
        data.img_thumb = url
    }

    if(uploadPDF){
        const {path, url} = await uploadFile(uploadPDF,"Descargables")
        data.path_descargar_pdf = path
        data.descargar_pdf = url
    }

    

    return data

}

export const addDescargable = async (data: any, images: DescargableFilesI) => {

    data = await handleUploadImages(data, images)

    const snap = await addDoc(collection(db, FS_FIELDS.DESCARGABLE), data)

    return {
        ...data,
        id:snap.id,
    }

}

export const updateDescargable = async (id: string, data: any, images: DescargableFilesI) => {

    data = await handleUploadImages(data, images)

    await updateDoc(doc(db, FS_FIELDS.DESCARGABLE, id), data)

    return data

}

export const deleteDescargable = async (id: string) => {

    
    return await updateDoc(doc(db, FS_FIELDS.DESCARGABLE, id), {
        activo: false
    })

}

export const getDescargable = async (id: string) => {

    const snap = await getDoc(doc(db, FS_FIELDS.DESCARGABLE, id))

    return snap.data()

}

export const getDescargables = async () => {

    const descargableRef = collection(
        db, FS_FIELDS.DESCARGABLE,
    )

    const snap = await getDocs(
        query(
            descargableRef,
            where("activo", "==", true),
            orderBy("fechaRegistro", "desc")

        )
    )

    return docsToArray(snap)

}