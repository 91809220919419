import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Dashboard from '../../components/Dashboard'
import DescargablesTable from '../../components/Descargables/Table'
import useFetchDescargables from '../../hooks/useFetchDescargables'

const DescargablesPage = () => {

  const navigate = useNavigate()
  const {} = useFetchDescargables()


  return (
    <Dashboard>
       <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin:'2% 0'
      }}>
      <h1>DESCARGABLES</h1>
      
        <Button variant='contained' color='success' style={{marginBottom: '20px', background: '#000',
            fontFamily: 'WorkSans',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#FFF',
            padding: '17px 62px',
            border: 'none',
            cursor: 'pointer', }} onClick={() => {
          navigate("/descargable/nuevo")
        }} >
          Nuevo descargable
        </Button>
      </div>
        <DescargablesTable />
    </Dashboard>
  )
}

export default DescargablesPage