import { Box, Button, TextField, TextareaAutosize } from '@mui/material'
import { FC } from 'react'
import UseForm from '../../../hooks/useForm'
import { NosotrosI } from '../../../models/nosotros'
import { updateNosotros } from '../../../services/nosotros'
import { handleChangeFile } from '../../../utils/files'
import CustomInputFile from '../../Custom/InputFile'
import Distribuidores from '../../Distribuidores'
import { Marcas } from '../../Marcas'
import ContactanosSection from '../Contactanos'
import CorporativoSection from '../Corporativo'

interface Props {
  value: NosotrosI
}

const NosotrosSection: FC<Props> = ({ value }) => {

  const { formData, onChange, setFormData } = UseForm(value || {})

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault()

    try {

      await updateNosotros({
        data: formData,
      })

      alert("Datos actualizados")

    } catch (e) {

      console.error("Error", e)

    } finally {

    }

  }

  return (
    <>
    <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin:'2% 0'
      }}>
      <h1>NOSOTROS</h1></div>
      <form onSubmit={onSubmit}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            background: '#fff',
            padding: '4%',
            paddingBottom: '10%'
          }}>

          <TextField value={formData.titulo} onChange={onChange}
            label="Titulo" variant='outlined' name="titulo"
            style={{ background: '#fff' }} required />
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Contenido"
            minRows={20}
            style={{ width: '100%', padding: '5px' }}
            required
            value={formData.descripcion_breve}
            name="descripcion_breve" onChange={onChange}

        />
        
        <CustomInputFile label='Imagen  (424 x 370px)' img={formData.imagen}
        onChange={e => {
          setFormData(prev=>({...prev,media:handleChangeFile(e)}))
        }} />

        <Marcas value={formData.marcas} onChange={e => {
          setFormData(prev=>({...prev,marcas:e}))
        }} />
        <Distribuidores value={formData.distribuidores}
        onChange={e => {
          setFormData(prev=>({...prev,distribuidores:e}))
        }} />
        <ContactanosSection value={formData.contacto}
        onChange={e => {
          setFormData(prev => ({ ...prev, contacto: e }))
        }} />
        <CorporativoSection value={formData.corporativo}
          onChange={e => {
            setFormData(prev => ({ ...prev, corporativo: e }))
          }} />
        <Button type='submit' variant='contained' style={{ width: '100px', background: '#000' }}>Guardar</Button>
      </Box>
      </form>

    </>

      
  )
}

export default NosotrosSection