import { useEffect, useState } from 'react'
import Dashboard from '../components/Dashboard'
import Loading from '../components/Loading'
import NosotrosSection from '../components/Sections/Nosotros'
import { ContactanosI, NosotrosI } from '../models/nosotros'
import { getContactanos, getNosotros } from '../services/nosotros'

const NosotrosPage = () => {

  const [nosotros, setNosotros] = useState<NosotrosI>()
  const [contactanos, setContactanos] = useState<ContactanosI>()

  useEffect(() => {
    const fetchNosotros = async () => {
      const nosoDB = await getNosotros()
      setNosotros(nosoDB)
    }

    const fetchContactacnos = async () => {
      const contactDB = await getContactanos()
      setContactanos(contactDB)
    }

    fetchNosotros()
    fetchContactacnos()
  }, [])

  return (
    <Dashboard>
         { !nosotros ? <Loading /> : <NosotrosSection value={nosotros} /> }
    </Dashboard>
  )
}

export default NosotrosPage