import ReactDOM from 'react-dom/client';
import App from './App';
import { BlogProvider } from './context/BlogContext';
import { DescargablesProvider } from './context/DescargablesContext';
import { UserProvider } from './context/UserContext';
import { VideoProvider } from './context/VideoContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { UserBarrilitoProvider } from './context/UsersBarrilitoContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <BlogProvider>
    <VideoProvider>
      <DescargablesProvider>
        <UserProvider>
          <UserBarrilitoProvider>
            <App />
          </UserBarrilitoProvider>
        </UserProvider>
      </DescargablesProvider>
    </VideoProvider>
  </BlogProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
