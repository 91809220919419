import { useParams } from 'react-router-dom'
import Dashboard from '../../components/Dashboard'
import Gallery from '../../components/Gallery'
import GalleryModalAdd from '../../components/Gallery/ModalAdd'
import Loading from '../../components/Loading'
import useFetchProduct from '../../hooks/useFetchProduct'
import { GalleriesI } from '../../models/productos'

const GalleryPage = () => {

  const { producto, setProducto } = useFetchProduct()
  const { id } = useParams()

  const onSuccess = (gallery: any) => {
    setProducto(prev => {

      if (!prev) return undefined

      return {
        ...prev,
        galeria: {
          ...prev.galeria,
          ...gallery.galeria,
        }
      }

    })
  }

  const onDelete = (id:string) => {
    setProducto(prev => {

      if(!prev) return undefined

      const galeria:GalleriesI = {}

      for(const j in prev.galeria) {
        if(j!==id){
          galeria[j] = {
            ...prev.galeria[j],
          }
        }
      }

      return {
        ...prev,
        galeria,
      }

    })
  }

  return (
    <Dashboard>
      <GalleryModalAdd productId={id as string} onSucces={onSuccess} />
      {producto ?
        <Gallery gallery={producto.galeria}
          title={producto.titulo} productId={id as string}
          onDelete={onDelete} />
        : <Loading />}
    </Dashboard>
  )
}

export default GalleryPage