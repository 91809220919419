import { FC } from "react"

interface Props {
    src?: string
    text?: string 
}

const ExternalLink: FC<Props> = ({src, text = "Abrir archivo"}) => {

    if(!src) return null

    return (
        <a href={src} target="_blank">
            {text}
        </a>
    )

    return (
        <video src={src} width={100} height={100} />
    )
}

export default ExternalLink