import CleaningServicesTwoToneIcon from '@mui/icons-material/CleaningServicesTwoTone';
import { TextField } from "@mui/material";
import { FC, useState } from "react";
import { ImageItemsI } from "../models/media";
import { handleChangeFile } from "../utils/files";
import CustomInputFile from "./Custom/InputFile";

interface Props {
    value: ImageItemsI
    onChange?: (e: ImageItemsI) => void,
    title?: string
}

const SelectImages: FC<Props> = ({ value, onChange, title }) => {

    const [images, setImages] = useState(value)

    const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const data = {
            [id]: {
                ...images[id],
                media: handleChangeFile(e),
            }
        }

        setImages(prev => ({
            ...prev,
            ...data
        }))
        onChange && onChange({
            ...images,
            ...data
        })
    }

    const clearImage = (id: string) => {
        const updatedImages = { ...images };
        updatedImages[id].imagen = "";
        setImages(updatedImages);
    };

    return (
        <div style={{
            background: 'white'
        }} className="contenedor">
            <h2>{title}</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {Object.keys(images).map(k => {
                    return <div key={k}>

                        <TextField label="URL"
                            value={images[k].url}
                            onChange={e => {
                                images[k].url = e.target.value
                                onChange && onChange(images)
                            }}
                        />

                        <CustomInputFile
                            label="Imagen (200 x 200px)" key={k}
                            onChange={e => onChangeImage(e, k)}
                            img={images[k].imagen}
                        />

                        <CleaningServicesTwoToneIcon onClick={() => clearImage(k)} />

                    </div>
                })}</div>
        </div>
    )
}

export default SelectImages