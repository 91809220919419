
import { Button } from '@mui/material'
import { FC } from 'react'

interface Props {
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    text: string
}

const UIButton: FC<Props> = ({ onClick, text }) => {
    return (
        <Button
            variant='contained'
            color='success'
            style={{
                marginBottom: '20px',
                background: '#000',
                fontFamily: 'WorkSans',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '16px',
                textAlign: 'center',
                textTransform: 'uppercase',
                color: '#FFF',
                padding: '17px 62px',
                border: 'none',
                cursor: 'pointer',
            }} onClick={onClick}>
            {text}
        </Button>
    )
}

export default UIButton