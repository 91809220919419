import { doc, getDoc, updateDoc } from "firebase/firestore"
import { FS_FIELDS } from "../../constants/db"
import { db } from "../../firebase/firebase"
import { HomeI, HomeMediasI } from "../../models/home"
import { handleUploadMedias } from "./utils"


export const getHome = async () => {

    const snap = await getDoc(
        doc(db, FS_FIELDS.SECCIONES, FS_FIELDS.HOME)
    )

    return snap.data() as HomeI

}

interface UpdateHomeProps {
    data: HomeI,
    medias:HomeMediasI,
}

export const updateHome = async ({data,medias}:UpdateHomeProps) => {

    data = await handleUploadMedias(data,medias)

    return await updateHomeData(data)

}

export const updateHomeData = async (data:any) => {

    return await updateDoc(
        doc(db, FS_FIELDS.SECCIONES, FS_FIELDS.HOME), 
        data as any
    )

}