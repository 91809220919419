import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore"
import { FS_FIELDS } from "../constants/db"
import { db } from "../firebase/firebase"
import { docsToArray } from "../utils/arrays"
import { uploadFile } from "./storage"

export interface BlogImagesI {
    imgThumb?: File
    imgPortada?: File
    imgContenido?: File
    imgDestacada?: File
}

const handleUploadImages = async (
    data: any, {imgContenido, imgPortada, imgThumb, imgDestacada}: BlogImagesI
) => {

    if (imgThumb) {
        const {path,url } = await uploadFile(imgThumb,"Blog")
        data.img_thumb = url
        data.path_img_thumb = path
    }

    if(imgContenido){
        const {path, url} = await uploadFile(imgContenido,"Blog")
        data.img_contenido = url
        data.path_img_contenido = path
    }

    if(imgPortada){
        const {path, url} = await uploadFile(imgPortada,"Blog")
        data.path_img_portada = path
        data.img_portada = url
    }

    if(imgDestacada){
        const {path, url} = await uploadFile(imgDestacada,"Blog")
        data.path_img_destacada = path
        data.img_destacada = url
    }

    return data

}

export const addBlog = async (data: any, images: BlogImagesI) => {

    data = await handleUploadImages(data, images)

    const snap = await addDoc(collection(db, FS_FIELDS.BLOG), data)

    return {
        ...data,
        id:snap.id,
    }

}

export const updateBlog = async (id: string, data: any, images: BlogImagesI) => {

    data = await handleUploadImages(data, images)

    await updateDoc(doc(db, FS_FIELDS.BLOG, id), data)

    return data

}

export const deleteBlog = async (id: string) => {

    return await updateDoc(doc(db, FS_FIELDS.BLOG, id), {
        activo: false
    })

}

export const getBlog = async (id: string) => {

    const snap = await getDoc(doc(db, FS_FIELDS.BLOG, id))

    return snap.data()

}

export const getBlogs = async () => {

    const blogRef = collection(
        db, FS_FIELDS.BLOG,
    )

    const snap = await getDocs(
        query(
            blogRef,
            where("activo", "==", true),
            orderBy("fechaRegistro", "desc")
        )
    )

    return docsToArray(snap)

}