import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { UserI } from '../../../models/users'
import { FC } from 'react'
import useUserAdd from './useUserAdd'


interface Props{
  user?: UserI
}

const UserAdd:FC<Props> = ({ user }) => {


  const navigate = useNavigate()

  const {
    onChange, handleSubmit,
    formData,
  } = useUserAdd(user)


  return (
    <Box component='form' onSubmit={handleSubmit}
        style={{ 
            backgroundColor: '#fff',
            display: "flex",
            flexDirection: "column",
            gap: 10,
            background: '#fff',
            padding: '4%',
            paddingBottom: '10%'
        }}>
        
        <h1>Admin</h1>

        <TextField label="Nombre" variant='outlined' name="nombre" onChange={onChange} value={formData.nombre} style={{ background: '#fff' }} required />

        <TextField label="Correo" type='email' variant='outlined' name="email" onChange={onChange} value={formData.email} style={{ background: '#fff' }} required />

        <TextField label="Contraseña" type='password' name="password" onChange={onChange} value={formData.password} variant='outlined' style={{ background: '#fff' }} required />

        <FormControl>
          <InputLabel >Rol</InputLabel>
          <Select name="rol" onChange={onChange} value={formData.rol} required>
            <MenuItem value={'Administrador'} >Administrador</MenuItem>
            <MenuItem value={'Editor'}>Editor</MenuItem>
          </Select>
        </FormControl>


        
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
          <Button color="success" variant="contained" type='submit' style={{
            background: '#000',
            fontFamily: 'WorkSans',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#FFF',
            padding: '17px 62px',
            border: 'none',
            cursor: 'pointer',
          }}  >Guardar</Button>
          
          <Button color="error" variant="contained" onClick={() => {
            navigate("/usuarios")
          }} style={{
            background: '#D8D8D8',
            fontFamily: 'WorkSans',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#5E5E5E',
            padding: '17px 62px',
            border: 'none',
            marginLeft: '20px',
            cursor: 'pointer',
          }}>Cancelar</Button>
        </div>



    </Box>
  )
}

export default UserAdd