import { Box } from "@mui/material"
import { FC } from "react"
import UseForm from "../../hooks/useForm"
import { MarcaI, MarcasI } from "../../models/marcas"
import MarcasItem from "./Item"

interface Props {
  value: MarcasI,
  onChange?: (e: MarcasI) => void,
}

export const Marcas: FC<Props> = ({ value, onChange }) => {

  const { formData, setFormData } = UseForm(value)

  const handleChange = (e:MarcaI, id:string) => {

    const data = {[id]:e}

    setFormData(prev=> ({
        ...prev,
        ...data,
    }))

    onChange && onChange({
        ...formData,
        ...data,
    })
}

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        background: '#fff',
        paddingBottom: '10%'
      }}>
      <h1>Marcas</h1>

      {
        Object.keys(formData).map(k => (
          <MarcasItem key={k} id={k} value={formData[k]}
          onChange={(e,id) => {
            handleChange(e,id)
          }} />
        ))
      }
    </Box>
  )
}
