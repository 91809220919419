import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { GalleriesI } from '../../../models/productos';
import { handleChangeFile } from '../../../utils/files';
import CustomInputFile from '../../Custom/InputFile';
import useGalleryModalAdd from './useGalleryModalAdd';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export interface GalleryModalAddProps {
    productId: string
    onSucces?: (e: { galeria: GalleriesI }) => void,
}

const GalleryModalAdd: FC<GalleryModalAddProps> = ({ productId, onSucces }) => {

    const {
        isOpen, handleClose, handleOpen,
        setImage, handleAdd, orden, setOrden
    } = useGalleryModalAdd({ productId, onSucces })

    return (
        <div style={{
            textAlign: 'right',
            marginTop: '40px'
        }}>
            <Button variant='contained' color='success' style={{ marginBottom: '20px' }} onClick={handleOpen} >
                Nueva imagen
            </Button>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style} component='form'>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <CustomInputFile label='Imagen' onChange={e => {
                            setImage(handleChangeFile(e))
                        }} />

                        <FormControl >
                            <TextField
                                label="Orden"
                                variant='outlined'
                                style={{ background: '#fff' }}
                                onChange={(e) => setOrden(parseInt(e.target.value))}
                                required
                            />
                            <Button style={{ marginTop: '10px' }} color='success' variant='contained'
                                onClick={() => handleAdd()}>Guardar</Button>
                        </FormControl>

                    </Typography>
                </Box>
            </Modal>

        </div>
    )
}

export default GalleryModalAdd