import {
    addDoc, collection, doc, getDoc, getDocs,
    orderBy, query, setDoc, updateDoc, where,
} from "firebase/firestore"
import { FS_FIELDS } from "../constants/db"
import { db } from "../firebase/firebase"
import { UserI } from "../models/users"
import { docsToArray } from "../utils/arrays"

export const addUser = async (data: any) => {

    if (data.id) {

        await setDoc(doc(db, FS_FIELDS.USUARIOS, data.id), data)

        return {
            ...data,
            id: data.id,
        }

    }

    const snap = await addDoc(collection(db, FS_FIELDS.USUARIOS), data)

    return {
        ...data,
        id: snap.id,
    }

}

export const getUser = async (id: string) => {

    const snap = await getDoc(doc(db, FS_FIELDS.USUARIOS, id))

    if (!snap.exists) return undefined

    return snap.data() as UserI

}

export const getUsers = async () => {

    const userRef = collection(
        db, "Usuarios",
    )

    const snap = await getDocs(
        query(
            userRef,
            where('activo', '==', true),
            where('rol', 'in', ['Administrador', 'Editor']),
            orderBy("fechaRegistro", "desc")
        )
    )

    return docsToArray(snap)

}

export const updateUser = async (id: string, data: any) => {


    await updateDoc(doc(db, FS_FIELDS.USUARIOS, id), data)

    return data

}

export const deleteUser = async (id: string) => {

    const url = `https://us-central1-barrilito-produccion.cloudfunctions.net/deleteUser`
    const response = await fetch(`${url}?userId=${id}`)

    if(response.status!==200) throw response.text

    return await response.json()

}