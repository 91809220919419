import { FC } from "react"
import { BlogI } from "../../../models/blog"
import useBlogAdd from "./useBlogAdd"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import CustomTextArea from "../../Custom/TextArea"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Checkbox from "@mui/material/Checkbox"
import CustomInputFile from "../../Custom/InputFile"
import Button from "@mui/material/Button"
import { MenuItem, Select } from "@mui/material"

interface Props {
  blog?: BlogI,
}

const BlogAdd: FC<Props> = ({ blog }) => {

  const {
    onChange, handleSubmit,
    formData, setImages,
    setFormData,
  } = useBlogAdd(blog)
  const navigate = useNavigate()


  return (
    <>

      <Box component='form' onSubmit={handleSubmit} style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        background: '#fff',
        padding: '4%',
        paddingBottom: '10%'

      }}>

        <h1>Blog</h1>

        <TextField label="Titulo" variant='outlined' value={formData.titulo} name="titulo" onChange={onChange} style={{ background: '#fff' }} required />

        <TextField label="Descripcion Breve" variant='outlined' value={formData.descripcion_breve} name="descripcion_breve" onChange={onChange} style={{ background: '#fff' }} required />

      
        <FormControl>
          <InputLabel >Categorias</InputLabel>
          <Select  name="categorias" onChange={onChange} value={formData.categorias}>
            <MenuItem value={'Educativo'}>Educativo</MenuItem>
            <MenuItem value={'Divertido'}>Divertido</MenuItem>
            <MenuItem value={'Recreativo'}>Recreativo</MenuItem>
            <MenuItem value={'Vida cotidiana'}>Vida cotidiana</MenuItem>
            <MenuItem value={'Aprendizaje'}>Aprendizaje</MenuItem>
            <MenuItem value={'Familia'}>Familia</MenuItem>
            <MenuItem value={'Actividades'}>Actividades</MenuItem>
            <MenuItem value={'Motricidad'}>Motricidad</MenuItem>
            <MenuItem value={'Temporalidades'}>Temporalidades</MenuItem>
            <MenuItem value={'Decoración'}>Decoración</MenuItem>
            <MenuItem value={'DIY'}>DIY</MenuItem>
            <MenuItem value={'Motivación'}>Motivación</MenuItem>
            <MenuItem value={'Motivación'}>Motivación</MenuItem>
            <MenuItem value={'Tips'}>Tips</MenuItem>
            <MenuItem value={'Desarrollo'}>Desarrollo</MenuItem>
          </Select>
        </FormControl>

      
      
        <CustomTextArea value={formData.contenido}
          onChange={(e) => {
            setFormData(prev => ({
              ...prev,
              contenido: e
            }))
          }} />

          
        <FormControl>
          <InputLabel>Destacado</InputLabel>

          <Checkbox checked={formData.destacado} onChange={() => {
            setFormData(prev=>({
              ...prev,
              destacado:!prev.destacado
            }))
          }} />
          
        </FormControl>

        <FormControl>
          <InputLabel>Restringido</InputLabel>

          <Checkbox checked={formData.restringido} onChange={() => {
              setFormData(prev=>({
                ...prev,
                restringido: !prev.restringido
              }))
            }} />
        </FormControl>

        <CustomInputFile label='Imagen thumb (848 x 566px)' onChange={e => {
          setImages(prev => ({
            ...prev,
            imgThumb: e.target.files ? e.target.files[0] : undefined
          }))
        }} img={formData.img_thumb} />

        <CustomInputFile label='Imagen Contenido (1600 x 643px)' onChange={e => {
          setImages(prev => ({
            ...prev,
            imgContenido: e.target.files ? e.target.files[0] : undefined
          }))
        }} img={formData.img_contenido} />

        <CustomInputFile label='Imagen Destacada (696 x 524px)' onChange={e => {
          setImages(prev => ({
            ...prev,
            imgDestacada: e.target.files ? e.target.files[0] : undefined
          }))
        }} img={formData.img_destacada} />

        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
          <Button variant="contained" type='submit' style={{
            background: '#000',
            fontFamily: 'WorkSans',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#FFF',
            padding: '17px 62px',
            border: 'none',
            cursor: 'pointer',
          }} >Guardar</Button>

          <Button variant="contained" onClick={() => {
            navigate("/blog")
          }} style={{
            background: '#D8D8D8',
            fontFamily: 'WorkSans',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#5E5E5E',
            padding: '17px 62px',
            border: 'none',
            marginLeft: '20px',
            cursor: 'pointer',
          }}>Cancelar</Button>
        </div>

      </Box>
    </>



  )
}

export default BlogAdd