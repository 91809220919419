import React, { useEffect, useState } from 'react'
import Dashboard from '../../components/Dashboard'
import UserAdd from '../../components/CreateUser/Add/Add'
import { useParams } from 'react-router-dom'
import { UserI } from '../../models/users'
import { isNewRegister } from '../../utils/strings'
import { getUser } from '../../services/users'

const AdminIdPage = () => {

  const {id} = useParams()
  const [user, setUser] = useState<UserI>()

  useEffect(() => {
    if(!id) return
    if(isNewRegister(id)) return

    const fetchUser = async () => {
      const userDB = await getUser(id) as any
      setUser(userDB)
    }

    fetchUser()

  }, [id])
  

  return (
    <Dashboard>
        <UserAdd user={user} key={user?.id}/>
    </Dashboard>
  )
}

export default AdminIdPage