import { DataGrid, GridColDef } from "@mui/x-data-grid"

interface Props {
    rows: readonly any[]
    columns: GridColDef<any>[]
}

const CustomTable = ({ rows, columns }: Props) => {


    return <DataGrid 
                rows={rows} 
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 15 },
                    },
                }}
                pageSizeOptions={[10, 15, 20, 25]}
            />
}

export default CustomTable