import { useEffect, useState } from "react"
import { useDescargablesContext } from "../context/DescargablesContext"
import { getDescargables } from "../services/descargable"

const useFetchDescargables = () => {

    const {descargables, setDescargables} = useDescargablesContext()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        if(descargables.length) return

        const fetchDescargables = async () => {


            const descargablesDB = await getDescargables()
            setDescargables(descargablesDB)
            setIsLoading(false)



        }

        fetchDescargables()

    }, [])

    return {
        isLoading
    }

}

export default useFetchDescargables