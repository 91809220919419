import { FC } from "react"

interface Props {
    src?: string
}

const ImgThumb: FC<Props> = ({ src }) => {

    if (!src) return null

    return (
        <img src={src} style={{
            width: 70,
            height: 70,
        }} />
    )
}

export default ImgThumb